import React from 'react'
import {roundCapacityValues} from '../../../../../../services/util/helperFuncs';
import {NoCapacitiesAvailableWarning} from '../../index';
import {DateService} from '../../../../../../services/util/DateService';
import {getTemplateStopTypeString} from '../../../../../../services/enums/TemplateStopType';

const Columns = {
  internalCustomerId: {
    name: 'internalCustomerId',
    header: ({t}) => t('internalCustomerId'),
    body: ({customer}) => customer.id
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({customer}) => customer.address?.streetAndNumber || '-'
  },
  zipcode: {
    name: 'zipcode',
    header: ({t}) => t('zipcode'),
    body: ({customer}) => customer.address?.zipcode || '-'
  },
  // This column is only available in TemplateStopMode since it requires the templateStop to be present on the stop on the template
  deliveryWindow: {
    name: 'deliveryWindow',
    header: ({t}) => t('deliveryWindow'),
    body: ({
      customer,
      customerIdToTemplateStopDict
    }) => DateService.displayTime(customerIdToTemplateStopDict[customer.id]?.deliveryWindow?.from) + ' - ' + DateService.displayTime(customerIdToTemplateStopDict[customer.id]?.deliveryWindow?.to)
  },
  // This column is only available in TemplateStopMode since it requires the templateStop to be present on the stop on the template
  stopType: {
    name: 'stopType',
    header: ({t}) => t('stopType'),
    body: ({
      customer,
      customerIdToTemplateStopDict,
      t
    }) => t(getTemplateStopTypeString(customerIdToTemplateStopDict[customer.id]?.stopType)) || '-'
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({t}) => t('boxAmount'),
    body: ({customerCapacities}) => customerCapacities
      ? roundCapacityValues(customerCapacities?.boxAmount)
      : <NoCapacitiesAvailableWarning/>
  },
  weight: {
    name: 'weight',
    header: ({t}) => t('weight'),
    body: ({customerCapacities}) => customerCapacities
      ? roundCapacityValues(customerCapacities?.weight)
      : <NoCapacitiesAvailableWarning/>
  },
  volume: {
    name: 'volume',
    header: ({t}) => t('volume'),
    body: ({customerCapacities}) => customerCapacities
      ? roundCapacityValues(customerCapacities?.volume)
      : <NoCapacitiesAvailableWarning/>
  },
}

export default Columns;