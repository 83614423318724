import { displayModes } from '../../../../services/enums/displayModes';
import Columns from './columns';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 1,
    columns: {
      stopNumber: {
        columnName: Columns.stopNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      tourStopId: {
        columnName: Columns.tourStopId.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 2
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 3
      },
      deliveryWindow: {
        columnName: Columns.deliveryWindow.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 4
      },
      stopType: {
        columnName: Columns.stopType.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 7
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 8
      },
    }
  },
  [displayModes.carrier]: {
    version: 1,
    columns: {
      stopNumber: {
        columnName: Columns.stopNumber.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      tourStopId: {
        columnName: Columns.tourStopId.name,
        optional: true,
        hidden: true,
        conditional: false,
        order: 2
      },
      shipper: {
        columnName: Columns.shipper.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 3
      },
      deliveryWindow: {
        columnName: Columns.deliveryWindow.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 4
      },
      stopType: {
        columnName: Columns.stopType.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      lastName: {
        columnName: Columns.lastName.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
      streetAndNumber: {
        columnName: Columns.streetAndNumber.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 7
      },
      zipcode: {
        columnName: Columns.zipcode.name,
        optional: true,
        hidden: false,
        conditional: false,
        order: 8
      },
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
  [displayModes.shared]: {
    columns: {}
  }
}

export default DefaultConfig;