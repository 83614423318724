import axios from 'axios';
import { AuthService } from 'services/auth/authService';
import { TemplateStopType } from '../enums/TemplateStopType';

const baseUri = `${process.env.REACT_APP_API_URI}templateStop`;

export class TemplateStopService {
  static async templateStopCreation(date, shipperName) {
    try {
      let newDate = new Date(date);
      let dateIsoString = newDate.toISOString();
      const reponse = await axios.get(`${baseUri}/templateStop/creationService?date=${dateIsoString}&shipperName=${shipperName}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
      return reponse.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getTemplateStopById(templateStopId) {
    const response = await axios.get(`${baseUri}/templateStop/${templateStopId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getTemplateStopsWithFilter(filter) {
    const response = await axios.get(`${baseUri}/templateStop/byFilter`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
        params: filter
      });
    return response.data;
  }

  static async deleteTemplateStopById(templateStopId) {
    return await axios.delete(`${baseUri}/templateStop/${templateStopId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
      });
  }

  static async updateTemplateStop(templateStop, templateStopId) {
    const response = await axios.put(`${baseUri}/templateStop/${templateStopId}`,
      templateStop,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
      });
    return response.data;
  }

  static async updatePickupDeliveryRelations(templateStop, oldRelatedTemplateStops, newRelatedTemplateStops) {
    const deletedRelations = oldRelatedTemplateStops.filter(oldRelatedTemplateStop => !newRelatedTemplateStops.some(newRelatedTemplateStop => oldRelatedTemplateStop.id === newRelatedTemplateStop.id));
    const addedRelations = newRelatedTemplateStops.filter(newRelatedTemplateStop => !oldRelatedTemplateStops.some(oldRelatedTemplateStop => newRelatedTemplateStop.id === oldRelatedTemplateStop.id));
    try {
      await axios.put(`${baseUri}/templateStop/deleteRelations`,
        deletedRelations.map((deletedRelation) => templateStop.stopType === TemplateStopType.Pickup ? {
          pickupStopId: templateStop.id,
          deliveryStopId: deletedRelation.id
        } : { pickupStopId: deletedRelation.id, deliveryStopId: templateStop.id })
        , {
          headers: {
            'Content-Type': 'application/json',
            authorization: AuthService.getAuthHeader()
          },
        });
    } catch (error) {
      console.log(error);
    }
    await axios.post(`${baseUri}/templateStop/relations`,
      addedRelations.map((addedRelation) => templateStop.stopType === TemplateStopType.Pickup ? {
        pickupStopId: templateStop.id,
        deliveryStopId: addedRelation.id
      } : { pickupStopId: addedRelation.id, deliveryStopId: templateStop.id })
      , {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
      })
  }

  static async createTemplateStop(templateStop) {
    const response = await axios.post(`${baseUri}/templateStop`, templateStop,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
      });
    return response.data;
  }

  static async getTemplateStopInstanceById(templateStopInstanceId) {
    const response = await axios.get(`${baseUri}/templateStopInstance/${templateStopInstanceId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getTemplateStopInstancesWithFilter(filter) {
    const response = await axios.get(`${baseUri}/templateStopInstance/byFilter`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
        params: filter
      });
    return response.data;
  }

  static async deleteTemplateStopInstanceById(templateStopInstanceId) {
    return await axios.delete(`${baseUri}/templateStopInstance/${templateStopInstanceId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
      });
  }

  static async getTemplateStopInstancesWithoutTourWithFilter(filter) {
    const response = await axios.get(`${baseUri}/templateStopInstance/withoutTour`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
        params: filter
      });
    return response.data;
  }

  static async getTourById(tourId) {
    const response = await axios.get(`${baseUri}/tour/${tourId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        }
      });
    return response.data;
  }

  static async getToursWithFilter(filter) {
    const response = await axios.get(`${baseUri}/tour/byFilter`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: AuthService.getAuthHeader()
        },
        params: filter
      });
    return response.data;
  }


}