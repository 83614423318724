import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TemplateStopTableRow from './TemplateStopTableRow';
import {Portlet, PortletContent, TableCellWithConfig, TableSettings} from '../../../../../components';
import {Droppable} from 'react-beautiful-dnd';
import {DndHelperElementTypes, DndHelperService} from '../../../../../services/dndHelper/dndHelperService';
import {displayModes} from '../../../../../services/enums/displayModes';
import useTableConfig from '../../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../services/enums/TableKind';
import CapacityModeDefaultConfig from './capacityModeConfig';
import TemplateStopModeConfig from './templateStopModeConfig'
import Columns from './columns';


function TemplateStopTable(props) {

  const {classes, t, template, className, deleteStopFromTemplate, capacityMode, displayMode, templateStopMode} = props;
  const rootClassName = classNames(className, classes.root);
  const uniqueTemplateId = DndHelperService.getUniqueTourId('{}', template.id);

  const defaultConfig = useTableConfig(DefaultConfig, displayMode, getTableKey(TableKind.TemplateStopTable, displayMode))

  const capacityModeConfig = useTableConfig(CapacityModeDefaultConfig, displayMode, getTableKey(TableKind.TemplateStopTable, displayMode, 'capacity_mode'))

  const templateModeConfig = useTableConfig(TemplateStopModeConfig, displayMode, getTableKey(TableKind.TemplateStopTable, displayMode, 'template_stop_mode'))

  let currentConfig = defaultConfig;
  if (templateStopMode) {
    currentConfig = templateModeConfig
  } else {
    currentConfig = capacityMode ? capacityModeConfig : defaultConfig
  }

  return (
    <div style={{position: 'relative'}}>
      <TableSettings
        updateConfig={currentConfig.updateConfig}
        tableConfig={currentConfig.config}
        columns={Columns}
        className={classes.tableSettings}
        buttonOptic
        useNamesAsTranslateString
      />
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <div style={{overflow: 'auto', maxHeight: '326px'}}>
            <Table
              size="medium"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {currentConfig.columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                      className={classes.tableHeader}
                      boxShadowColor={columnConfig.order === 1 ? template.color : null}
                    >
                      {Columns[columnConfig.columnName].header({t, classes})}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              </TableHead>
              <Droppable
                droppableId={uniqueTemplateId}
                type={DndHelperElementTypes.Stop}
              >
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {template.stops.map((stop, index) => {
                      return (
                        <TemplateStopTableRow
                          columnConfigs={currentConfig.columnConfigs}
                          deleteStopFromTemplate={(stopId) => deleteStopFromTemplate(stopId, template)}
                          index={index}
                          key={stop.id}
                          stop={stop}
                          uniqueTemplateId={uniqueTemplateId}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </div>
        </PortletContent>
      </Portlet>
    </div>
  );
}

TemplateStopTable.propTypes = {
  capacityMode: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteStopFromTemplate: PropTypes.func.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  t: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  templateStopMode: PropTypes.bool,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopTable);
