import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {SideNavLayout} from '../../../layouts';
import MicroHubMeasurementQueryService from "../../../services/backend/microHubMeasurementQueryService";
import {
    DataLoadingWrapper,
    DropdownSelectSimple,
    MicroHubSensorConfigCreateDialog,
    MicroHubSensorConfigDetail,
    MicroHubSensorConfigTable,
    TextPlaceholderWrapper,
} from "../../../components";
import {displayModes} from "../../../services/enums/displayModes";
import {Add as AddIcon} from "@material-ui/icons";
import useCarriersWithHubs from "../../../hooks/useCarriersWithHubs";

function SensorConfig(props) {
    const {classes, t} = props;

    const [backendLoading, setBackendLoading] = useState(false);
    const [microHubSensorConfig, setMicroHubSensorConfig] = useState([]);
    const [selectedMicroHubSensorConfig, setSelectedMicroHubSensorConfig] = useState(null);
    const [createMicroHubSensorConfigDialogState, setCreateMicroHubSensorConfigDialogState] = useState({
        open: false
    });
    const [carrierFilter, setCarrierFilter] = useState('');
    const {microHubOptions, carrierOptions} = useCarriersWithHubs();
    
    const loadMicroHubSensorConfig = useCallback(async () => {
        setBackendLoading(true);
        try {
            const microHubSensorConfig = await MicroHubMeasurementQueryService.getMicroHubSensorConfig(carrierFilter);
            setMicroHubSensorConfig(microHubSensorConfig);
            setBackendLoading(false);
        } catch (error) {
            alert(`${t('errorLoadingMicroHubSensorConfig')}, ${error}`);
            setBackendLoading(false);
        }
    }, [t, carrierFilter]);
    
    const deleteMicroHubSensorConfigCallback = useCallback(async (microHubSensorConfig, event) => {
        event.stopPropagation();
        try {
            await MicroHubMeasurementQueryService.deleteMicroHubSensorConfig(microHubSensorConfig.sensorId, microHubSensorConfig.microHubName);
            loadMicroHubSensorConfig();
            setSelectedMicroHubSensorConfig(null);
        }
        catch (error) {
            alert(`${t('errorDeletingMicroHubSensorConfig')}, ${error}`);
        }
    }, [loadMicroHubSensorConfig, t]);

    const createMicroHubSensorConfigCallback = useCallback(async (microHubSensorConfig) => {
        try {
            await MicroHubMeasurementQueryService.createMicroHubSensorConfig(microHubSensorConfig.sensorId, microHubSensorConfig.microHubName);
            loadMicroHubSensorConfig();
            setSelectedMicroHubSensorConfig(null);
        }
        catch (error) {
            alert(`${t('errorCreatingMicroHubSensorConfig')}, ${error}`);
        }
    }, [loadMicroHubSensorConfig, t]);

    const updateMicroHubSensorConfigCallback = useCallback(async (microHubSensorConfig) => {
        try {
            await MicroHubMeasurementQueryService.updateMicroHubSensorConfig(parseInt(microHubSensorConfig.sensorId), microHubSensorConfig.microHubName, parseInt(microHubSensorConfig.id));
            loadMicroHubSensorConfig();
            setSelectedMicroHubSensorConfig(null);
        }
        catch (error) {
            alert(`${t('errorUpdateMicroHubSensorConfig')}, ${error}`);
        }
    }, [loadMicroHubSensorConfig, t]);
    
    const handleCreateMicroHubSensorConfigCancel = () => {
        setCreateMicroHubSensorConfigDialogState({...createMicroHubSensorConfigDialogState, open: false});
    };

    const handleCreateMicroHubSensorConfigClose = (microHubSensorConfig) => {
        microHubSensorConfig.sensorId= parseInt(microHubSensorConfig.sensorId)
        try{
            createMicroHubSensorConfigCallback(microHubSensorConfig);
            loadMicroHubSensorConfig();
        }
        catch (error) {
            alert(`${t('errorCreatingMicroHubSensorConfig')}, ${error}`);
        }
        setCreateMicroHubSensorConfigDialogState({...createMicroHubSensorConfigDialogState, open: false});
    };

    useEffect(() => {
        loadMicroHubSensorConfig();
    }, [loadMicroHubSensorConfig]);
    
    return (
        <SideNavLayout title={t('microHubSensorConfig')}>
            <div className={classes.root}>
                <DropdownSelectSimple
                    className={classes.formControl}
                    name={t('carrier')}
                    options={carrierOptions}
                    setValue={(value) => setCarrierFilter(value)}
                    sort
                    value={carrierFilter}
                />
                <div>
                    <Button 
                        className={classes.addButton}
                        color="primary"
                        disabled={microHubOptions && microHubOptions.length <= 0}
                        onClick={() => setCreateMicroHubSensorConfigDialogState({
                            ...createMicroHubSensorConfigDialogState,
                            open: true
                        })}
                        title={t('createMicroHubSensorConfig')}
                        variant="contained"
                    >
                        <AddIcon/>&nbsp;{t('createMicroHubSensorConfig')}
                    </Button>
                    <br/>
                    <br/>
                </div>
                <DataLoadingWrapper
                    isLoading={backendLoading}
                >
                    <div>
                        <TextPlaceholderWrapper
                            active={(microHubSensorConfig && microHubSensorConfig.length <= 0)}
                            text={t('noMicroHubSensorConfig')}
                        >
                            {microHubSensorConfig && microHubSensorConfig.length > 0 && (
                                <Fragment>
                                    <MicroHubSensorConfigTable
                                        microHubSensorConfigs={microHubSensorConfig}
                                        displayMode={displayModes.admin}
                                        deleteMicroHubSensorConfig={deleteMicroHubSensorConfigCallback}
                                        className={classes.content}
                                        selectMicroHubSensorConfig={setSelectedMicroHubSensorConfig}
                                        selectedMicroHubSensorConfig={selectedMicroHubSensorConfig}
                                    />
                                    {microHubOptions && selectedMicroHubSensorConfig !== null &&
                                        <MicroHubSensorConfigDetail
                                            microHubSensorConfig={selectedMicroHubSensorConfig}
                                            className={classes.content}
                                            closeDetail={() => setSelectedMicroHubSensorConfig(null)}
                                            microHubNames={microHubOptions}
                                            updateMicroHubSensorConfig={updateMicroHubSensorConfigCallback}
                                        />
                                    }
                                </Fragment>
                            )}
                        </TextPlaceholderWrapper>
                    </div>
                </DataLoadingWrapper>
                <MicroHubSensorConfigCreateDialog
                    createMicroHubSensorConfigDialogState={createMicroHubSensorConfigDialogState}
                    handleCancel={handleCreateMicroHubSensorConfigCancel}
                    handleClose={handleCreateMicroHubSensorConfigClose}
                    microHubNames={microHubOptions}
                />
            </div>
        </SideNavLayout>
    );
}

SensorConfig.propTypes = {
    classes: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(SensorConfig);